import dayjs from 'dayjs'

import { currentDevice } from '../../core/utils/currentDevice'
import generatePaxConfig from '../../utils/generatePaxConfig'
import { loadDataLastSearches } from '../last_searches/last_searches'

import { FASTBOOKING_EVENTS, IBH_FASTBOOKING_EVENTS, STANDARD_DATE_FORMAT } from './constants'

class Fastbooking {
  constructor() {
    this.fastbooking = document.querySelector('#ibh-fastbooking')
    this.isInitialized = false
    this.isSticky = false
    this.state = {}
    this.stateUpdatedEvent = new Event(FASTBOOKING_EVENTS.STATE_UPDATED)
    this.dateFormat = document.documentElement.dataset?.dateFormat || 'DD/MM/YYYY'
    this.tab = 'hotel'
  }

  setAsInitialized() {
    this.isInitialized = true

    const setInitializedEvent = new CustomEvent(FASTBOOKING_EVENTS.FASTBOOKING_READY)
    document.dispatchEvent(setInitializedEvent)
  }

  /**
   * SETTERS
   */

  // Sets room code in fastbooking form input hidden to be sent to CRS on submit
  setRoomCode(roomCode) {
    this.fastbooking.querySelector('.js-fastbooking-room-code').value = roomCode
  }

  setDestination(destinationId, options) {
    if (!destinationId) return

    const setHotelEvent = new CustomEvent(FASTBOOKING_EVENTS.SET_DESTINATION, {
      detail: {
        destinationId,
        options,
      },
    })

    document.dispatchEvent(setHotelEvent)
  }

  setDates(checkIn, checkOut) {
    if (!checkIn || !checkOut) return

    const setDatesEvent = new CustomEvent(FASTBOOKING_EVENTS.SET_DATES, {
      detail: {
        dates: {
          checkIn,
          checkOut,
        },
      },
    })

    document.dispatchEvent(setDatesEvent)
  }

  setOccupancy(paxConfig) {
    if (!paxConfig) return

    const setOccupancyEvent = new CustomEvent(FASTBOOKING_EVENTS.SET_OCCUPANCY, {
      detail: {
        paxConfig,
      },
    })

    document.dispatchEvent(setOccupancyEvent)
  }

  setPromocode(code) {
    if (!code) return

    const setPromocodeEvent = new CustomEvent(FASTBOOKING_EVENTS.SET_PROMOCODE, {
      detail: {
        code,
      },
    })

    document.dispatchEvent(setPromocodeEvent)

    document.addEventListener(
      FASTBOOKING_EVENTS.PROMOCODE_IS_VALID,
      () => {
        const showBannerEvent = new CustomEvent(IBH_FASTBOOKING_EVENTS.SHOW_BANNER)

        document.dispatchEvent(showBannerEvent)
      },
      { once: true }
    )
  }

  /**
   * PANELS
   */

  openDates(startDate) {
    const openDatesEvent = new CustomEvent(FASTBOOKING_EVENTS.OPEN_DATES, {
      detail: startDate,
    })
    document.dispatchEvent(openDatesEvent)
  }

  openOccupancy() {
    const openOccupancyEvent = new CustomEvent(FASTBOOKING_EVENTS.OPEN_OCCUPANCY)
    document.dispatchEvent(openOccupancyEvent)
  }

  openPromocode() {
    const openPromocodeEvent = new CustomEvent(FASTBOOKING_EVENTS.OPEN_PROMOCODE)
    document.dispatchEvent(openPromocodeEvent)
  }

  closePanels() {
    const event = new CustomEvent(FASTBOOKING_EVENTS.CLOSE_PANELS)
    document.dispatchEvent(event)
  }

  /**
   * STICKY
   */

  setFastbookingSticky() {
    const event = new CustomEvent(IBH_FASTBOOKING_EVENTS.STICKY_FASTBOOKING)
    document.dispatchEvent(event)
    this.isSticky = true
  }

  unsetFastbookingSticky() {
    const event = new CustomEvent(IBH_FASTBOOKING_EVENTS.NO_STICKY_FASTBOOKING)
    document.dispatchEvent(event)
    this.hideOverlay()
    this.isSticky = false
  }

  openStickyFastbooking() {
    const event = new CustomEvent(IBH_FASTBOOKING_EVENTS.OPEN_STICKY_FASTBOOKING)
    document.dispatchEvent(event)
    this.showOverlay()
    this.isSticky = true
  }

  closeStickyFastbooking() {
    const event = new CustomEvent(IBH_FASTBOOKING_EVENTS.CLOSE_STICKY_FASTBOOKING)
    document.dispatchEvent(event)
    this.hideOverlay()
    this.isSticky = true
  }

  /**
   * OVERLAY
   */

  showOverlay() {
    if (currentDevice.isMobile) {
      const overlayEvent = new CustomEvent(FASTBOOKING_EVENTS.FASTBOOKING_ACTIVE, { detail: { isSticky: true } })
      document.dispatchEvent(overlayEvent)
    }
  }

  hideOverlay() {
    if (currentDevice.isMobile) {
      const overlayEvent = new CustomEvent(FASTBOOKING_EVENTS.FASTBOOKING_INACTIVE, { detail: { isSticky: true } })
      document.dispatchEvent(overlayEvent)
    }
  }

  /**
   * FASTBOOKING STATE
   */

  getFastbookingStateFromStorage() {
    const isValidDateFormat = dateString => /^\d{4}-\d{2}-\d{2}$/.test(dateString)

    const item = localStorage.getItem('fastbooking_state')

    if (!item) return

    const state = JSON.parse(item)

    if (state.dates?.check_in && !isValidDateFormat(state.dates.check_in)) {
      state.dates = null
    }

    return state
  }

  // Gets fastbooking state and applies it in fastbooking selectors
  applyFastbookingState(source = 'state') {
    const state = source === 'storage' ? this.getFastbookingStateFromStorage() : this.state

    if (!state) return

    if (state.place_to_go?.id_and_type) {
      this.setDestination(state.place_to_go.id_and_type, { prevent_show_dates: true })
    }

    this.applyFastbookingStateDates(source)

    if (state.rooms_info) {
      const paxConfig = generatePaxConfig(state.rooms_info)
      this.setOccupancy(paxConfig)
    }

    if (state.promocode?.code) this.setPromocode(state.promocode.code)
  }

  // Given an id gets the matching last search and applies it in fastbooking selectors
  applyFastbookingStateFromLastSearch(destinationId) {
    if (!destinationId) return

    const search = this.getLastSearchById(destinationId, { prevent_show_dates: true })

    if (!search) return

    this.setDestination(search.id)

    if (search.check_in_date && search.check_out_date) {
      this.setDates(search.check_in_date, search.check_out_date)
    }

    if (search.pax_config) {
      this.setOccupancy(search.pax_config)
    }
  }

  // Gets fastbooking state dates and applies them in fastbooking date selector
  applyFastbookingStateDates(source = 'state') {
    const state = source === 'storage' ? this.getFastbookingStateFromStorage() : this.state

    if (!state) return

    if (state.dates?.check_in && state.dates?.check_out) {
      this.setDates(state.dates.check_in, state.dates.check_out)
    }
  }

  // Stores data in fastbooking state
  setFastbookingState(data) {
    if (!data) return

    this.state = { ...this.state, ...data }

    document.dispatchEvent(this.stateUpdatedEvent)
  }

  // Stores fastbooking state in local storage
  storeFastbookingStateInLocalStorage() {
    localStorage.setItem('fastbooking_state', JSON.stringify(this.state))

    document.dispatchEvent(this.stateUpdatedEvent)
  }

  /**
   * LAST SEARCHES
   */
  areSearchesDifferent(search1, search2) {
    const relevantFields = ['check_in_date', 'check_out_date', 'rooms', 'pc']
    for (const field of relevantFields) {
      if (search1[field] !== search2[field]) {
        return true
      }
    }
    return false
  }

  getLastSearchById(destinationId) {
    const lastSearches = JSON.parse(localStorage.getItem('last_searches')) || []

    return lastSearches.find(search => search.id === destinationId)
  }

  getDestinationFromFastbookingData(id, data) {
    const sections = ['destinations', 'hotels', 'specialties']

    for (const section of sections) {
      const items = data[section][1] // Accede al array correspondiente

      for (const item of items) {
        if (item.id === id) {
          return { item }
        }
      }
    }

    return {} // ID no encontrado
  }

  storeLastSearchInLocalStorage(search, fastbookingData) {
    const maxSearches = 3 //guardar las últimas 3 búsquedas

    const lastSearches = JSON.parse(localStorage.getItem('last_searches')) || []
    const item = this.getDestinationFromFastbookingData(search.destination, fastbookingData).item

    const lastSearch = {
      category: item.data.hotel_category,
      check_in_date: search.checkIn ? dayjs(search.checkIn, this.dateFormat).format(STANDARD_DATE_FORMAT) : null,
      check_out_date: search.checkOut ? dayjs(search.checkOut, this.dateFormat).format(STANDARD_DATE_FORMAT) : null,
      datesInfoKey: null,
      day: '',
      description: '',
      image_alt: '',
      image_desktop: '',
      image_mobile: '',
      image_tablet: '',
      image: '',
      locale: document.documentElement.getAttribute('data-js-lang'),
      next_opening: item.data.next_opening,
      path: '',
      pax_config: '',
      pc: search.promocode,
      place_title: '',
      place_to_go: item.id,
      rooms: search.occupancy,
      search_url: '',
      segmentations: item.data.segmentations,
      summary: '',
      title_normalize: item.title_normalize,
      title: item.title,
      title_en: item.title_en,
      type: item.type,
    }

    if (lastSearch) {
      const dataLastSearches = loadDataLastSearches(lastSearch)
      lastSearch.pax_config = dataLastSearches.pax_config
      lastSearch.search_url = dataLastSearches.search_url

      const existingItem = lastSearches.find(item => item.id === lastSearch.place_to_go)
      const existingItemIndex = lastSearches.findIndex(item => item.id === lastSearch.place_to_go)

      if (existingItem) {
        if (this.areSearchesDifferent(existingItem, lastSearch)) {
          lastSearches[existingItemIndex] = { ...lastSearch } // Reemplazar con la nueva búsqueda
        }
      } else {
        lastSearches.push({ ...lastSearch })
      }
      if (lastSearches.length > maxSearches) {
        lastSearches.shift() // Eliminar la búsqueda más antigua
      }
    }

    localStorage.setItem('last_searches', JSON.stringify(lastSearches))
  }

  getLastSearches(fastbookingData) {
    if (!fastbookingData) return
    const localStorageLastSearches = localStorage.getItem('last_searches')

    const ibhLastSearches = []

    if (localStorageLastSearches) {
      const lastSearches = JSON.parse(localStorageLastSearches)

      lastSearches.forEach(lastSearch => {
        const item = this.getDestinationFromFastbookingData(lastSearch.place_to_go, fastbookingData).item
        const checkIn = lastSearch.check_in_date ?? null
        const checkOut = lastSearch.check_out_date ?? null

        const ibhLastSearch = {
          destination: {
            id: item.id,
            label: item.title,
          },
          id: item.id,
          checkInDate: checkIn,
          checkOutDate: checkOut,
          data: {
            adults_only: item.data.adults_only,
            max_occupancy: item.data.max_occupancy,
            max_adults: item.data.max_adults,
            max_children: item.data.max_children,
            next_opening: item.data.next_opening,
          },
          promocode: lastSearch.pc,
          pax_config: lastSearch.pax_config ?? '',
          type: item.type,
          title: item.title,
          title_en: item.title_en,
          title_normalize: item.title_normalize,
        }
        ibhLastSearches.push(ibhLastSearch)
      })
    }
    return ibhLastSearches
  }

  /**
   * TABS
   */

  setTab(tab) {
    this.tab = tab
  }

  getTab() {
    return this.tab
  }
}

const fastbooking = new Fastbooking()
window.fb = fastbooking

export default fastbooking
